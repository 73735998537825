<template>
    <div class="page-pstn-manager-allocate container">
        <div class="row">
            <div class="col-12 heading-box">
                <h1 class="heading">Allocate Numbers</h1>
            </div>
            
            <div class="col-12">
                <app-error v-model="errors.show" :message="errors.message"></app-error>
            </div>
            
            <div class="col-12">
                <app-search-box
                    v-model="number"
                    label="Phone number"

                    :loading="loading"

                    @search="onSearch"
                />
            </div>

            <div class="col-12" v-if="is_number_find">
                <app-table
                    class="numbers-list-table"

                    :sort-value="current_sort_value"
                    :sort-options="sort"
                    @sort-change="onSortChange"

                    :cols="cols"
                    :rows="rows"

                    :loading="loading"
                ></app-table>

                <app-pagination
                    v-if="pagination.total"

                    v-model="pagination.page"

                    :total="pagination.total"
                    :limit="pagination.limit"
                    :page-range="pagination.range"

                    @change="onPageChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import appError from '@/components/app-error'

import appSearchBox from '@/components/app-search-box'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

const NUMBERS_LIST = [
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899dq',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899dw',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899de',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899dr',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899dt',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899dy',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899du',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899di',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899do',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899dp',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899da',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899ds',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899dd',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899df',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899dg',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899dh',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899dj',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899dk',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899dl',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899dz',
    },
    {
        Number: '+64219191191',
        AllocationStatus: 'Any Allocation Status',
        ServiceUUID: 'c86cd2fd-5ab7-4395-a024-c6a5b23899dx',
    },
]

export default {
    components: {
        appSearchBox,
        appTable,
        appPagination,
        appError,
    },

    data() {
        return {
            number: '',

            numbers_list: [],

            current_sort_value: {},

            sort: [
                { field: 'Number',           title: 'Number'            },
                { field: 'AllocationStatus', title: 'Allocation Status' },
                { field: 'ServiceUUID',      title: 'Service UUID'      },
            ],

            cols: [
                { key: 'Number',           title: 'Number',            sort: { field: 'Number' },           },
                { key: 'AllocationStatus', title: 'Allocation Status', sort: { field: 'Allocation Status' },},
                { key: 'ServiceUUID',      title: 'Service UUID',      sort: { field: 'Service UUID' },     },
            ],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            errors: {
                show: false,
                message: '',
            },
        }
    },

    methods: {
        onSortChange(sort) {
            this.current_sort_value = sort

            this.getNumbersList()
        },

        onPageChange(page) {
            this.getNumbersList(page)
        },

        onSearch(value) {
            const number = value.trim()

            let page = 1

            this.getNumbersList(page)
        },

        getNumbersList(page) {
            const delay_in_ms = 1000

            this.loading = true

            setTimeout(() => {
                const list = []

                const start = ( ( page || this.pagination.page ) - 1 ) * this.pagination.limit
                const total = NUMBERS_LIST.length
                const end = start + this.pagination.limit < total ? start + this.pagination.limit : total

                for (let i = start; i < end; i++) {
                    list.push(NUMBERS_LIST[i])
                }

                this.numbers_list = list

                this.pagination.page = page || this.pagination.page
                this.pagination.total = total

                if (this.$route.params.page != this.pagination.page
                    && !(
                        this.$route.params.page === undefined
                        && this.pagination.page === 1
                    )
                ) {
                    this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                }

                this.errors = {
                    show: false,
                    message: '',
                }

                this.loading = false
            }, delay_in_ms)
        },
    },

    computed: {
        rows() {
            const rows = []

            for (let i = 0, len = this.numbers_list.length; i < len; i++) {
                const number = this.numbers_list[i]

                rows.push({
                    Number:           number.Number,
                    AllocationStatus: number.AllocationStatus,
                    ServiceUUID:      number.ServiceUUID,
                })
            }
            return rows
        },

        is_number_find() {
            return Boolean(this.numbers_list.length)
        },
    },
}
</script>

<style lang="scss">
.page-pstn-manager-allocate {
    padding-bottom: 80px;

    .heading-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 48px;

    }

    .app-error,
    .app-search-box {
        margin-bottom: 30px;
    }

    .numbers-list-table {
        @include table-cols-width((180px, 280px, 300px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-pstn-manager-allocate {
        .numbers-list-table {
            @include table-cols-width((120px, 160px, 250px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-pstn-manager-allocate {
        .heading-box {
            margin-bottom: 32px;
        }

        .app-error,
        .app-search-box {
            margin-bottom: 15px;
        }

        .numbers-list-table {
            @include table-cols-width-mobile((110px, 165px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>